<template>
  <div class="y-page">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'Task',
  mounted() {
  },
  // //download-cos.yofish.com/y-taskcenter-gongjushiyebu/20220113142958838-headBg1.png
};
</script>
<style lang="scss" scoped>
.y-page {
  background: #F5F5F5;
}
</style>
